import React, { useState } from 'react';
import { slice, uniqBy } from 'lodash';
import {
  Avatar, IconButton, ListItemText, Popover, Tooltip,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Credit } from 'lib/types/credits';
import { getAvatarUrlForCredit } from 'lib/utils/imageUtils';
import { CreditItem } from 'components/CreditItem';
import { AddCreditAvatar } from 'components/AddCreditAvatar';
import { useCurrentUser } from 'lib/contexts/ApplicationState';

const AVATAR_SIZE = 30;

interface Props {
  credits: Credit[];
  numCreditsToShow?: number;
  path?: string;
}

export const CreditsPreview = ({
  credits, numCreditsToShow = 5, path = '/',
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const currentUser = useCurrentUser();

  const toggle = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl && credits?.length > 0) {
      setAnchorEl(event.currentTarget);
      return;
    }
    setAnchorEl(null);
  };

  const close = () => {
    setAnchorEl(null);
  };

  const renderAddCreditBtn = (title: string) => {
    if (!currentUser?.id) {
      return null;
    }
    return <AddCreditAvatar title={title} path={path} />;
  };

  const renderCreditAvatars = () => {
    if (credits?.length === 0) {
      return renderAddCreditBtn('This post has no credits yet, add some now!');
    }
    const deDupedCreditsByUser = uniqBy(credits, 'userId');
    const creditsDisplayed = (deDupedCreditsByUser
        && deDupedCreditsByUser.length > numCreditsToShow)
      ? slice(deDupedCreditsByUser, 0, numCreditsToShow) : deDupedCreditsByUser;
    return (
      <>
        {creditsDisplayed.map((credit) => {
          const creditUserName = credit.isPrivate ? 'Private credit' : credit?.user?.name;
          if (credit.isHidden) {
            return null;
          }

          return (
            <Tooltip
              title={(
                <>
                  <div className="text-sm font-semibold">{creditUserName}</div>
                  {credit?.role?.name}
                </>
              )}
              key={credit?.id}
              arrow
            >
              <Avatar
                className="mr-1"
                sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
                key={credit.id}
                variant="circular"
                src={getAvatarUrlForCredit(
                  credit.user.id, credit.user.avatarUrl, credit.isPrivate,
                )}
                alt={creditUserName}
                imgProps={{ loading: 'lazy' }}
              />
            </Tooltip>
          );
        })}
        {deDupedCreditsByUser.length > numCreditsToShow ? (
          <Avatar sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}>
            {`+${deDupedCreditsByUser.length - numCreditsToShow}`}
          </Avatar>
        ) : renderAddCreditBtn('Add a credit')}
      </>
    );
  };

  const additionalCreditsPrimary = () => {
    const text = 'more credit(s)';
    return (
      <p className="font-bold">
        +
        {credits.length - numCreditsToShow}
        {' '}
        {text}
      </p>
    );
  };

  const additionalCreditsSecondary = () => {
    const text = 'Including:';
    return (
      <div>
        {text}
        {' '}
        {credits.slice(numCreditsToShow).map(
          (c) => (
            c.user?.name
          ),
        ).join(', ')}
      </div>
    );
  };

  return (
    <>
      <button
        type="button"
        className="flex p-2 w-full hover:bg-hintgray"
        onClick={toggle}
      >
        {renderCreditAvatars()}
      </button>
      <Popover
        id="credit-preview"
        open={open}
        anchorEl={anchorEl}
        onClose={close}
        PaperProps={{
          sx: {
            pointerEvents: 'auto',
            width: 280,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="p-4">
          <div className="absolute right-2 top-2">
            <IconButton
              aria-label="close"
              onClick={close}
            >
              <Close />
            </IconButton>
          </div>
          <div className="mb-2">
            {/* TODO: sort hidden credits to bottom */}
            {credits?.slice(0, numCreditsToShow).map(
              (credit) => (
                <CreditItem
                  key={credit.id}
                  credit={credit}
                  parentCredit={credit.parentCredit}
                  parentCreditPrivate={credit.parentCredit?.isPrivate}
                />
              ),
            )}
            {credits?.length > numCreditsToShow && (
              <ListItemText
                primary={additionalCreditsPrimary()}
                secondary={additionalCreditsSecondary()}
              />
            )}
          </div>
        </div>
      </Popover>
    </>
  );
};
