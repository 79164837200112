import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { abbreviateNum } from 'lib/helpers';
import { Post } from 'lib/types';
import { ProIcon } from 'components/ProBadge/ProIcon';
import { NETWORK_IDS } from 'lib/constants';
import { DateTime } from 'luxon';
import { MobileTooltip } from 'components/MobileTooltip';
import { compact } from 'lodash';
import { getAllowedMetrics } from './helpers';
import { MetricProps } from './types';

interface Props {
  post: Post;
  decimals: number;
  size?: string;
  isScraping?: boolean;
  isPostCard?: boolean;
  onScrapePost?: () => void;
}

export const PostMetrics = ({
  post, decimals, size, isScraping = false, isPostCard = false, onScrapePost,
}: Props) => {
  const renderRefreshStats = () => {
    if (!post.isLive || post.network === NETWORK_IDS.facebook || !onScrapePost) {
      return null;
    }

    const timeAgoBase = DateTime
      .fromISO(post.statsUpdatedAt || post.createdAt)
      .toRelative();

    const timeAgo = timeAgoBase?.match(/seconds/) ? 'just now' : timeAgoBase;

    return (
      <div>
        <MobileTooltip
          title={(
            <p className="flex items-center">
              <ProIcon width={20} height={24} />
              <span className="ml-2">
                Refresh post stats now (updated
                {' '}
                {timeAgo}
                )
              </span>
            </p>
          )}
          placement="top"
        >

          <button type="button" className="w-full text-center text-orange" onClick={onScrapePost}>
            <RefreshIcon />
          </button>
        </MobileTooltip>
      </div>
    );
  };

  const renderMetrics = () => {
    const allowedMetrics = getAllowedMetrics(post.network, isPostCard);
    const metricData = allowedMetrics.map((metric: MetricProps) => {
      const metricValue = post[metric.field] as number;
      if (!metricValue && !metric.renderIfNull) {
        return null;
      }
      return (
        <div
          key={`post-${post.id}-${metric.field}`}
        >
          <p className="font-light">
            {metric.icon}
            {' '}
            {abbreviateNum(metricValue, decimals)}
          </p>
        </div>
      );
    });
    return compact([...metricData, renderRefreshStats()]);
  };

  return (
    <>
      <div className={`flex justify-evenly items-center text-center py-2 ${size === 'small' && 'text-sm'} ${isScraping ? 'animate-pulse' : ''}`}>
        {renderMetrics()}
      </div>
    </>
  );
};
